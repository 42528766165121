/* your styles go here */
body {
    scrollbar-width: thin;          /* "auto" or "thin"  */
    scrollbar-color: #888 transparent;   /* scroll thumb & track */
}
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
::-webkit-scrollbar-track {
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background: #888;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.fit-parent {
    flex: 1 1 auto;
    min-height: 1px;
}

.flex-1-0 {
    flex: 1 0 auto;
}

.flex-1-1 {
    flex: 1 1 auto;
}

.min-wrapper {
    min-width: 991px;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.table-fixed {
    table-layout: fixed;
}

.table-vertical-middle th, .table-vertical-middle td {
    vertical-align: middle;
}

.table-vertical-middle th {
    white-space: nowrap;
}

.table-fixed th, .table-fixed td {
    overflow-x: hidden;
}

.z-index-1 {
    z-index: 1;
}
.app-table {
    display: flex!important;
    flex-direction: column!important;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0!important;
}
@media (min-width: 576px) {
    .app-table {
        padding: 1rem!important;
    }
}
.app-table-wrapper {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    position: relative!important;
    -ms-flex-positive: 1!important;
    flex-grow: 1!important;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.app-table-header {
    max-height: 48px;
    z-index: 1;
    overflow-y: scroll;
    min-width: 991px;
    flex: 1 0 auto;
}
.app-table-body {
    overflow-y: scroll;
    min-width: 991px;
    flex: 1 1 auto;
    max-height: 100%!important;
}

.main-header {
    width: 100%;
    background: #2f333e;
    color: #fff;
    padding: 20px 10px;
    text-align: center;
}

.main-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: rgba(39, 174, 97, 0.2);
    /*font-family: 'Montserrat', sans-serif;*/
}

.side-navbar.shrinked footer {
    display: none;
}

.absolute-top {
    position: absolute;
    top: 0;
    width: 100%;
}

.absolute-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.sort-me {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin: -0.75rem;
    padding: 0.75rem;
}

.table-responsive.flex-grow-1 {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}

#table-log {
    font-size: 0.85rem;
}

.sidebar {
    font-size: 0.85rem;
    line-height: 1;
}

.stack {
    font-size: 0.85em;
}

.word-break-all {
    word-break: break-all;
}

.list-group-item {
    word-wrap: break-word;
}

.dropdown-menu .active {
    background: #2b90d9;
    color: #fff;
    border-left: 4px solid #1c669c;
}

.dropdown-menu .folder .active {
    background: #b0e1e880;
    color: #000000;
    border-left: 4px solid #1c669c;
}

.div-scroll {
    overflow: hidden auto;
}

.nowrap {
    white-space: nowrap;
}

/* ==============================
   4. Page loader
   ============================== */

#mask {
    background-color: #000000;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 100000;
}

.loader {
    position: absolute;
    width: 80px;
    height: 80px;
    margin: -40px 0 0 -40px;
    top: 50%;
    left: 50%;
}

noscript {
    display: inline-block;
    text-align: center;
    color: white;
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

#login-logo {
    max-width: 150px;
    width: 45%;
    min-width: 100px;
}

.btn-facebook {
    background-color: #3b5998;
    border-color: #3c5a99;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
}

.btn-twitter {
    background-color: #00ACED;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
}

.btn-linkedin {
    background-color: #007BB6;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
}

.btn-google-plus {
    background-color: #DD4B39;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
}

.btn-instagram {
    background-color: #517FA4;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
}

.btn-social {
    position: relative;
    padding-left: 44px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.btn-social :first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    line-height: 34px;
    font-size: 1.6em;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2)
}

.btn-social.btn-lg {
    padding-left: 61px
}

.btn-social.btn-lg :first-child {
    line-height: 45px;
    width: 45px;
    font-size: 1.8em
}

.btn-social.btn-sm {
    padding-left: 38px
}

.btn-social.btn-sm :first-child {
    line-height: 28px;
    width: 28px;
    font-size: 1.4em
}

.btn-social.btn-xs {
    padding-left: 30px
}

.btn-social.btn-xs :first-child {
    line-height: 20px;
    width: 20px;
    font-size: 1.2em
}

.btn-social-icon {
    position: relative;
    padding-left: 44px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 34px;
    width: 34px;;
    padding-right: 0
}

.btn-social-icon :first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    line-height: 34px;
    font-size: 1.6em;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2)
}

.btn-social-icon.btn-lg {
    padding-left: 61px
}

.btn-social-icon.btn-lg :first-child {
    line-height: 45px;
    width: 45px;
    font-size: 1.8em
}

.btn-social-icon.btn-sm {
    padding-left: 38px
}

.btn-social-icon.btn-sm :first-child {
    line-height: 28px;
    width: 28px;
    font-size: 1.4em
}

.btn-social-icon.btn-xs {
    padding-left: 30px
}

.btn-social-icon.btn-xs :first-child {
    line-height: 20px;
    width: 20px;
    font-size: 1.2em
}

.btn-social-icon :first-child {
    border: none;
    text-align: center;
    width: 100% !important
}

.btn-social-icon.btn-lg {
    height: 45px;
    width: 45px;
    padding-left: 0;
    padding-right: 0
}

.btn-social-icon.btn-sm {
    height: 30px;
    width: 30px;
    padding-left: 0;
    padding-right: 0
}

.btn-social-icon.btn-xs {
    height: 22px;
    width: 22px;
    padding-left: 0;
    padding-right: 0
}


.text-dark {
    color: #2b3d51 !important;
}


/*===== Loader =====*/
.preloader {
    width: 30px;
    height: 30px;
    position: relative;
    margin: 55px auto;
}

.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #205c81;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: bounce 2.0s infinite ease-in-out;
    animation: bounce 2.0s infinite ease-in-out;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

/* ========== Nav =========== */

.nav-item {
    padding: 0 3px;
}

.nav-item a {
    padding: 0 6px;
}

.nav-item a i.fa {
    margin-right: 4px;
}

.page {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.page-content {
    display: flex;
    flex: 1 1 auto;
    min-height: 1px;
    align-items: stretch;
    position: relative;
}

nav.side-navbar {
    position: absolute;
    bottom: 0;
    top: 0;
    max-height: 100%;
    overflow: hidden;
}
nav.side-navbar>.sidebar-body {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
}
nav.side-navbar>.sidebar-body::-webkit-scrollbar {
    width: 7px;
}
nav.side-navbar>.sidebar-body::-webkit-scrollbar-track {
    background: transparent;
}
nav.side-navbar>.sidebar-body::-webkit-scrollbar-thumb {
    background: #888;
}
nav.side-navbar>.sidebar-body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.content-inner main {
    display: flex;
    flex: 1 1 auto;
    min-height: 1px;
}

.page-header h2 i.fa {
    margin-right: 10px;
}

.projects {
    padding: 5px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 1px;
    max-width: 100%;
}
@media (min-width: 576px) {
    .projects {
        padding: 15px!important;
    }
}

.shrinked .hidden-shrinked {
    display: none;
}

.relative {
    position: relative;
}

#gallery .project .project-title .text {
    width: calc(100% - 100px);
}

#gallery .project .project-title .text p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#gallery .project .left-col {
    flex-wrap: wrap;
}

#gallery .project .left-col .project-date {
    padding: 4px;
}

#gallery .project .left-col .project-date select {
    display: inline-block;
    max-width: 190px;
}

#gallery .project .image .overlay {
    cursor: pointer;
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(10, 10, 10, 0.5);
}

#gallery .project .image:hover .overlay, .project .image .overlay:hover {
    display: block;
}

#gallery .project .image .overlay:before {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "\f044";
    color: white;
}

#galleryModal .image {
    min-width: 200px;
    text-align: center;
}

#galleryModal .image img {
    max-height: 200px;
    width: auto;
    margin: 0 auto;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#recipes .list-group-item {
    padding: 4px 8px;
    margin-left: 4px;
}

/*.card-close .dropdown-menu a.text-danger{*/
/*color: #d9534f!important;*/
/*}*/
.cke_button__editmode_label {
    display: inline !important;
}

.cke_button__editmode_icon {
    display: none !important;
}

.col-inline-4 {
    padding: 5px;
    width: 100%;
    float: left;
    display: inline-block;
}

@media (min-width: 992px) {
    .col-inline-4 {
        width: 33.3333%;
    }
}

.mr-7 {
    margin-right: 70px;
}

.absolute-right {
    position: absolute;
    right: 0;
    top: 0;
}

.absolute-left {
    position: absolute;
    left: 0;
    top: 0;
}

.pointer {
    cursor: pointer;
}

.custom-select.is-invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .was-validated .form-control:invalid {
    border-color: #dc3545;
}

.custom-select.is-invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .was-validated .form-control:invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, .25);
}

.custom-select.is-valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .was-validated .form-control:valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, .25);
}

.custom-select.is-valid, .form-control.is-valid, .was-validated .custom-select:valid, .was-validated .form-control:valid {
    border-color: #28a745;
}

.ui-select-toggle.btn {
    border: 1px solid #ced4da;
}

.ui-select-container.open .ui-select-choices.dropdown-menu {
    display: block;
}
.ui-select-container.open .ui-select-no-choice.dropdown-menu {
    display: block;
}
.ui-select-match-text {
    max-height: 24px;
}

.ui-select-match.btn-default-focus {
    border-radius: 0;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, .25);
    outline: 0;
}

.ui-select-bootstrap .ui-select-choices-row.active > span {
    background: #2b90d9;
    color: #fff;
    border-left: 4px solid #1c669c;
}
.ui-select-bootstrap .ui-select-no-choice>li>span {
    cursor: pointer;
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    border-left: 4px solid #1c669c;
}
.ui-select-bootstrap .ui-select-match-text span {
    white-space: nowrap;
}
.ui-select-bootstrap .form-control:focus {
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, .25);
}

.ui-select-match.btn-default-focus > .ui-select-toggle {
    border-color: #80bdff;
}

.custom-append {
    position: relative;
    padding-right: 38px;
    clear: both;
}

.custom-prepend {
    position: relative;
    padding-left: 38px;
    clear: both;
}

.custom-append-addon {
    position: absolute;
    top: 0;
    width: 38px;
}
.custom-append-addon:first-child {
    left: 1px;
}
.custom-append-addon:last-child {
    right: 1px;
}

.ui-select-bootstrap .btn, .ui-select-bootstrap .dropdown-menu {
    border-radius: 0;
}

.custom-append-sm {
    font-size: .875rem;
    padding-right: 29px;
}

.custom-append-sm .custom-append-addon {
    font-size: .875rem;
    width: 29px;
}

.custom-append-sm .custom-append-addon .btn {
    width: 29px;
    padding: .25rem .5rem;
}

.custom-append-sm .btn, .custom-append-sm .form-control {
    font-size: .875rem;
    height: calc(1.5em + .5rem + 2px);
}


.custom-prepend-sm {
    font-size: .875rem;
    padding-left: 29px;
}

.custom-prepend-sm .custom-append-addon {
    font-size: .875rem;
    width: 29px;
}

.custom-prepend-sm .custom-append-addon .btn {
    width: 29px;
    padding: .25rem .5rem;
}

.custom-prepend-sm .btn, .custom-prepend-sm .form-control {
    font-size: .875rem;
    height: calc(1.5em + .5rem + 2px);
}

.custom-append > .input-group-append > .btn,
.custom-append > .input-group-append > .input-group-text,
.custom-append > .input-group-prepend:first-child > .btn:not(:first-child),
.custom-append > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.custom-append > .input-group-prepend:not(:first-child) > .btn,
.custom-append > .input-group-prepend:not(:first-child) > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    max-width: 100%;
}

.custom-prepend > .input-group-prepend > .btn,
.custom-prepend > .input-group-prepend > .input-group-text,
.custom-prepend > .input-group-prepend:last-child > .btn:not(:last-child),
.custom-prepend > .input-group-prepend:last-child > .input-group-text:not(:last-child),
.custom-prepend > .input-group-prepend:not(:last-child) > .btn,
.custom-prepend > .input-group-prepend:not(:last-child) > .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    max-width: 100%;
}

.custom-append > .ui-select-container:not(:last-child) .ui-select-toggle.btn,
.custom-append > .ui-select-container:not(:last-child) .form-control,
.custom-append > .ui-select-container:not(:last-child) .ui-select-match.btn-default-focus,
.custom-prepend > .ui-select-container:not(:last-child) .ui-select-toggle.btn,
.custom-prepend > .ui-select-container:not(:last-child) .form-control,
.custom-prepend > .ui-select-container:not(:last-child) .ui-select-match.btn-default-focus {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.custom-append > .ui-select-container:not(:last-child) .ui-select-match.btn-default-focus {
    background: transparent;
}

.ui-select-multiple.ui-select-bootstrap.form-control {
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
}
.ui-select-bootstrap .form-control {
    width: 100%!important;
}

.ui-select-bootstrap .ui-select-choices-row > span {
    white-space: normal;
}

.ui-select-bootstrap .ui-select-placeholder {
    white-space: nowrap;
    overflow-x: hidden;
    max-width: 100%;
}

.ui-select-container .btn-default:hover, .ui-select-container .btn-default {
    background-color: #fff;
}

.toast-notifications {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10000;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-width: 320px;
}

.toast {
    pointer-events: all !important;
}

.toast-dark {
    background: #525a65;
}

.toast-dark .toast-header {
    color: white;
    background: #2f333e;
}

.toast-dark .toast-body {
    color: white;
}

.custom-checkbox label, .custom-checkbox input, .form-group label {
    cursor: pointer;
}

.uib-datepicker-popup .text-muted, .uib-datepicker-popup .text-info {
    color: white !important;
}


.input-group .dropdown-menu .active a:hover {
    color: #fff;
}
.input-group [uib-typeahead-popup].dropdown-menu a {
    padding: .25rem 1.5rem;
}

[uib-typeahead-popup].dropdown-menu {
    padding: .5rem 0;
    width: 100%;
    overflow-x: hidden;
}
[uib-typeahead-popup].dropdown-menu .active .dropdown-item {
    color: #fff;
}
[uib-typeahead-popup].dropdown-menu .active .dropdown-item:hover {
    background: #2b90d9;
    color: #fff;
}
.form-floating {
    position: relative;
}
.form-floating>.form-control, .form-floating>.form-select {
    height: calc(3.5rem + 2px);
    padding: 1rem .75rem;
}
.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem .75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out;
}
.form-floating>.form-control:focus {
    padding-top: 1.625rem;
    padding-bottom: .625rem;
}
.form-floating>.form-control, .form-floating>.form-select {
    height: calc(3.5rem + 2px);
    padding: 1rem .75rem;
}
.form-floating>.form-control:focus~label, .form-floating>.form-select~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem);
}
